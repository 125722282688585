



















































.nav_bar{
   background: #ffffff;
}

.nav_bar_por{
  background: #ffffff;
}

.titleStyle{
  background: #ffffff;
}

.nav_bar{
  background: red;
}
.nav_bar_por {
  background: #ffffff;
}
.gonggao_list {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 65px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .top {
    color: #999999;
    font-size: 0.3rem;
    margin-left: 0.4rem;
    margin-top: 14px;
  }
  .two {
    color: #999999;
    font-size: 0.28rem;
    margin-left: 0.4rem;
    margin-top: 2px;
  }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;