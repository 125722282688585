.nav_bar {
  background: #ffffff;
}
.nav_bar_por {
  background: #ffffff;
}
.titleStyle {
  background: #ffffff;
}
.nav_bar {
  background: red;
}
.nav_bar_por {
  background: #ffffff;
}
.gonggao_list {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 65px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.gonggao_list .top {
  color: #999999;
  font-size: 0.3rem;
  margin-left: 0.4rem;
  margin-top: 14px;
}
.gonggao_list .two {
  color: #999999;
  font-size: 0.28rem;
  margin-left: 0.4rem;
  margin-top: 2px;
}
